import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Network } from '@ionic-native/network/ngx';
import {IonicStorageModule} from '@ionic/storage-angular';
import { NetworkServices } from './services/networkServices';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { ClientServices } from './services/clientServices';
import { StorageServices } from './services/storageService';
import { OrderServices } from './services/orderServices';
import { StringUtils } from './utils/stringUtils';
import { NumberUtils } from './utils/numberUtils';
import { HeaderServices } from './services/headerServices';
import { NotificationServices } from './services/notificationServices';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserServices } from './services/userServices';
import { CatalogServices } from './services/catalogServices';
import { TabServices } from './services/tabServices';
import { PagoServices } from './services/pagoServices';
import { CarteraServices } from './services/carteraServices';
import { ObjectUtils } from './utils/objectUtils';
import { ReaccionServices } from './services/reaccionServices';
import { LoginServices } from './services/loginServices';
import { ClientInfoModule } from './components/client-info/client-info.module';
import { LoadingModule } from './components/loading/loading.module';
import { PagoItemModule } from './components/pago-item/pago-item.module';
import { SearchClientPageModule } from './components/search-client/search-client.module';
import { ConfigVendorPageModule } from './pages/configPages/config-vendor/config-vendor.module';
import { SyncCatalogsPageModule } from './pages/configPages/sync-catalogs/sync-catalogs.module';
import { BodegaServices } from './services/bodegaServices';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';

import { QRCodeModule } from 'angularx-qrcode';
import { PrintServices } from './services/printServices';
import { SafePipe } from './pipes/safePipe';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { SolicitudTrasladoServices } from './services/solicitudTrasladoService';
import { PickingLoteComponent } from './pages/bodega/picking-produccion/procesar-picking-produccion/picking-lote/picking-lote.component';
import { ProcesarPickingProduccionPageModule } from './pages/bodega/picking-produccion/procesar-picking-produccion/procesar-picking-produccion.module';
import { PickingServices } from './services/pickingServices';
import { ReadQRComponent } from './components/read-qr/read-qr.component';
import { BuscaBodegaComponent } from './components/busca-bodega/busca-bodega.component';
import { SearchComboComponent } from './components/search-combo/search-combo.component';
import { SocioNegocioServices } from './services/socioNegocioServices';
import { EscogerDocOrigenComponent } from './pages/bodega/EntradaMercancia/escoger-doc-origen/escoger-doc-origen.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent, 
    SafePipe, 
    PickingLoteComponent, 
    ProductInfoComponent,
    ReadQRComponent, 
    BuscaBodegaComponent, 
    SearchComboComponent, 
    EscogerDocOrigenComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    HttpClientModule,
    ClientInfoModule,
    LoadingModule,
    PagoItemModule,
    SearchClientPageModule,
    ConfigVendorPageModule,
    SyncCatalogsPageModule,
    FormsModule,
    QRCodeModule,
    ReactiveFormsModule,
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot(),
    AppRoutingModule],
  exports:[
    SafePipe
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NetworkServices,
    ClientServices,
    StorageServices,
    OrderServices,
    StringUtils,
    HeaderServices,
    NotificationServices,    
    UserServices,
    CatalogServices,
    TabServices,
    PagoServices,
    NumberUtils,
    ObjectUtils,
    CarteraServices,
    ReaccionServices,
    SolicitudTrasladoServices,
    LoginServices,
    BodegaServices,
    PrintServices,
    LocationAccuracy,
    Printer,
    PickingServices,
    SocioNegocioServices
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
