import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogServices } from 'src/app/services/catalogServices';

@Component({
  selector: 'app-busca-bodega',
  templateUrl: './busca-bodega.component.html',
  styleUrls: ['./busca-bodega.component.scss'],
})
export class BuscaBodegaComponent implements OnInit {
  _bodega:any;
  @Input()
  get bodega(){
    return this._bodega
  }
  set bodega(value){
    
    if(!value)
      return;
    if(this._bodega && this._bodega.name && value.name && (this._bodega.name == value.name))
      return;
    this._bodega=value;
    console.log(value);
    console.log('emit');
    this.bodegaChange.emit(value);
  }
  @Output() bodegaChange = new EventEmitter();

  @Input()
  label:string;
  @Input()
  conUbicaciones:boolean;
  

  bodegas: any[]=[];

  constructor(private catalogService: CatalogServices) { }

  ngOnInit() {
    this.setCatalogoBodega();
  }
  onBodegaChange(bodega){
    this.bodega=bodega;
  }
  setCatalogoBodega(){
    var catalogName=this.conUbicaciones?'bodegasConUbicaciones':'bodegas';
    let me=this.catalogService.getCatalogByName(catalogName);
    if(me && me.Error){
      console.log('Error: catalogo bodegas '+me.Error);
    }else{
      me.Bodegas.forEach(bodega=>{
        this.bodegas.push({
          "name": bodega
        });
      });
    }
  }

}
