import { BaseTxObj } from "./baseTxObj";
import { DocCarteraMsg } from "./carteraMsg";

export class CacheItemMsg extends BaseTxObj{
    toSync:boolean;
    CodCliente: string;
    client: string;
    synchronized: boolean;
    col1: string;
    col2: string;
    
    constructor(){
        super();
    }
}
