import { BaseTxObj } from "./baseTxObj";

export class DocCarteraMsg{
    total:number=0;
    toPay:number=0;
    numDoc:string;
    date:string;
    dueDate:string;
    selected:boolean;
    habilitado:boolean;
    tipoDocumento:string;
    editedFromCache:boolean;
    isOnCache: boolean;
    pagado: number=0;
    retenciones: any[]=[];
    pagos: any={};
    porcentajeProntoPago: number=0;
    otroPorcentajeProntoPago: number=0;
    toPayMasProntoPago: number=0;
    porcentajePP: number=0;
    static getPorcentajePP(me):number{
        me.porcentajePP = 
            (me.porcentajeProntoPago== -1)?
                me.otroPorcentajeProntoPago:
                me.porcentajeProntoPago;
        return me.porcentajePP;
    }
    static getToPayMasProntoPago(me):number{
        //El pronto pago se aplica al total de la factura
        me.toPayMasProntoPago= me.toPay - me.total*(DocCarteraMsg.getPorcentajePP(me)/100);;
        return me.toPayMasProntoPago;
    }
    static getMontoRetenciones(me){
        let ms=0;
        if(me.retenciones)
        me.retenciones.forEach(ret=>ms+=ret.Valor);
        return ms;
    }
}
