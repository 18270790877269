
import { AlertController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { NetworkServices } from '../services/networkServices';
export class BasePage{
    
    constructor(public ac: AlertController){}
  
    async show(msg, title='Mensaje', subtitle='') {
        const alert = await this.ac.create({
            cssClass: 'my-custom-class',
            header: title,
            subHeader: subtitle,
            message: msg,
            buttons: ['OK']
        });
        await alert.present();
    }

}