import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { BasePage } from 'src/app/pages/basePage';
import { PickingServices } from 'src/app/services/pickingServices';

@Component({
  selector: 'app-picking-lote',
  templateUrl: './picking-lote.component.html',
  styleUrls: ['./picking-lote.component.scss'],
})
export class PickingLoteComponent extends BasePage{
  cantidad: number;
  current:any;
  //bodegaDestino:any={name:'PROD2'}
  bodegaOrigen:any={};
  constructor(ac:AlertController,private modalCtrl: ModalController) { 
    super(ac);
    this.current=PickingServices.currentComponent;
    console.log(this.current);
    if(this.current && this.current.BodegaOrigen){
      this.bodegaOrigen={name: this.current.BodegaOrigen};
      console.log(this.bodegaOrigen);
    }
    
  }
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  confirm() {
    if(this.valid())
      return this.modalCtrl.dismiss(this.current, 'confirm');
  }
  valid(){
    if(!this.ubicacionCorrecta(this.current.ubicacionSeleccionada)){
      this.show("El producto no se encuentra en la ubicación seleccionada, Elija una ubicación correcta!!")
      return false;
    }
    if(this.current.Lote!==this.current.loteSeleccionado){
      this.show("El lote seleccionado no corresponde con lo solicitado")
      return false;
    }
    if(!this.current.cantidadEnviada || this.current.cantidadEnviada<this.current.Cantidad){
      this.show("La cantidad enviada no puede ser menor a la solicitada")
      return false;
    }
    if(!this.cantEnUbicacionCorrecta(this.current.ubicacionSeleccionada)){
      this.show('La cantidad a enviar es superior a la que existe en la ubicación!!');
      return false;
    }
    if(!this.current.BodegaDestino){
      this.show("Debe indicar la bodega destino!!")
      return false;
    }
    return true;
  }
  cantEnUbicacionCorrecta(ubicacion){
    for(let u of this.current.Ubicaciones){
      if(u.Ubicacion==ubicacion){
        if(this.current.cantidadEnviada<=u.Cantidad){
          return true;
        }
      }
    }
    return false;
  }
  ubicacionCorrecta(ubicacion){
    for(let u of this.current.Ubicaciones){
      if(u.Ubicacion==ubicacion)
        return true;
    }
    return false;
  }
}
