import { Network } from '@ionic-native/network/ngx';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class NetworkServices{
    
    netWorkConnected: Subject<boolean>;
     //navivator is used on browser test
    //network used on device
    constructor(n: Network){
        this.netWorkConnected=new Subject<boolean>();
        n.onConnect().subscribe(()=>{
            this.netWorkConnected.next(true);
        });
        n.onDisconnect().subscribe(()=>{
            this.netWorkConnected.next(false);
        });
        
        this.checkNavigatorOnline();
    }

    onNetworkChange(): Observable<boolean>{
        return this.netWorkConnected.asObservable();
    }
    checkNavigatorOnline() {
        var that = this;
        setTimeout(()=> {
            that.checkNavigatorOnline();
            this.netWorkConnected.next(navigator.onLine);
        }, 3000);
    } 
}