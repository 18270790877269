import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { CatalogServices } from 'src/app/services/catalogServices';
import { ClientServices } from 'src/app/services/clientServices';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

@Component({
  selector: 'app-search-client',
  templateUrl: './search-client.page.html',
  styleUrls: ['./search-client.page.scss'],
})
export class SearchClientPage implements OnInit {
  clients: any[]=[];
  clientsResp: any[]=[];
  showClientInfo: boolean=true;
  @Output() onClick: EventEmitter<any> = new EventEmitter()
  @ViewChild("clientInput",{static: false}) clientInput: IonSearchbar;
  constructor(public cs: ClientServices,catalogServices: CatalogServices) { 
    var catalogClients=catalogServices.getCatalogByName('clients');
    this.clients=catalogClients;
    this.clientsResp=catalogClients;
    catalogServices.onCacheCatalogUpdated().subscribe(catalogName=>{
      if(catalogName=='clients'){
        var catalogClients=catalogServices.getCatalogByName('clients');
        this.clients=catalogClients;
        this.clientsResp=catalogClients;
      }
    });
  }
  ionViewWillEnter() {
    this.setAutoFocus();
  }
  
  ngOnInit() {
    
  }
  setAutoFocus(){
    console.log('entro a cliente');
    //setAutoFocus on search bar "clientInput";
    setTimeout(() => this.clientInput.setFocus(), 300);
  }
  showResult(){
    if(!this.clientsResp || this.clientsResp.length == 0)
        this.clientsResp = this.clients; 
  }
  searchClient(me){
    if(!this.clients)
      return;
    
    this.cs.selectedClient=null;
    
    let token = me.detail.value;
    if(token && token!='')
      this.clientsResp=this.filter(token);
    else
      this.clientsResp=this.clients;
    this.clientsResp= this.clientsResp.sort((a,b)=>{ //sort by name
      if(a.name>b.name)
        return 1;
      return -1;
    });
  }
  filter(token){
    let ms=[];
    token = token.toLocaleLowerCase();
    
    const matrixToken = token.split(' ');
    for (let client of this.clients) {
      let strInToSearch=client.name;
      if(client.comercialName)
        strInToSearch+=' '+client.comercialName;
      if(client.directions){
        client.directions.forEach(direction=>{
          strInToSearch+=' '+direction.city;
        })
      }
      strInToSearch=strInToSearch.toLocaleLowerCase();
      if (ArrayUtils.contieneTokens(strInToSearch, matrixToken)) {
        ms.push(client);
      }
    }
    return  ms;
  }
  selectClient(client){
    this.cs.selectedClient=client;
    this.cs.selectClient(client);
    this.setSelectedClientView();
    this.onClick.emit(client);
  }
  setSelectedClientView() {
    this.showClientInfo=true;
    this.clientsResp=[];
  }
}
