import { Network } from '@ionic-native/network/ngx';
import { Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NetworkServices } from './networkServices';
import { StorageServices } from './storageService';
import { StringUtils } from '../utils/stringUtils';
import { ConfigUtils } from '../utils/configUtils';
import { UserServices } from './userServices';
import { timer } from 'rxjs';

@Injectable()
export class CatalogServices{
    catalogs: any[]=[
        {name: "Cartera", catalogName: 'cartera', data:null, date: null, requireVendor:true, ver:false},
        {name: "Clientes", catalogName: 'clients', data:null, date: null, requireVendor:true, ver:false},
        {name: "Productos", catalogName: 'products', data:null, date: null, requireVendor:false, ver:false},
        {name: "Bancos", catalogName: 'banks', data:null, date: null, requireVendor:false, ver:false},
        {name: "Pedidos", catalogName: 'syncOrders', data:null, date: null, requireVendor:true, ver:false},
        {name: "Reacciones", catalogName: 'catalogReacciones', data:null, date: null, requireVendor:false, ver:false},
        {name: "Bodegas con Ubicaciones", catalogName: 'bodegasConUbicaciones', data:null, date: null, requireVendor:false, ver:false},
        {name: "Bodegas", catalogName: 'bodegas', data:null, date: null, requireVendor:false, ver:false},
        {name: "Ubicaciones", catalogName: 'ubicaciones', data:null, date: null, requireVendor:false, ver:false},
        {name: "ProveedoresEM", catalogName: 'catalogProveedoresEM', data:null, date: null, requireVendor:false, ver:false}
      ];
    online: boolean=false;
    //syncOrders
    private syncMessage: Subject<string>=new Subject<string>();
    private errorToSyncCatalog: Subject<any>=new Subject<any>();
    private catalogsRemoved: Subject<any>=new Subject<any>();
    private sincronizandoCatalogos: Subject<any>=new Subject<any>();
    private cacheCatalogUpdated: Subject<string>=new Subject<string>();
    private catalogLoaded: Subject<string>=new Subject<string>();
    //cuando desde el apartado de configuraciones se forza el sincronizado
    //de un catálogo
    private catalogSyncronized: Subject<string>=new Subject<string>();
    private sincronizandoCatalogo: boolean;
    constructor(
            private http: HttpClient, 
            networkService: NetworkServices,
            private storageService: StorageServices, 
            private userService: UserServices
        ){
            networkService.onNetworkChange().subscribe(online=>{
                this.online=online;
            });
    }
   
    onSincronizandoCatalogos():Observable<string>{
        return this.sincronizandoCatalogos.asObservable();
    }
    onCatalogLoaded():Observable<string>{
        return this.catalogLoaded.asObservable();
    }
    onCatalogSyncronized():Observable<string>{
        return this.catalogSyncronized.asObservable();
    }
    onCacheCatalogUpdated():Observable<string>{
        return this.cacheCatalogUpdated.asObservable();
    }
    onErrorToSyncCatalog():Observable<any>{
        return this.errorToSyncCatalog.asObservable();
    }
    onCatalogsRemoved():Observable<any>{
        return this.catalogsRemoved.asObservable();
    }
    onSyncMessage():Observable<string>{
        return this.syncMessage.asObservable();
    }
    //se cargan todos los catálogos al iniciarse el sistema
    prepareCacheCatalogs(catalogName=null){
        let catalogsToProcess=[];
        /*
            solo carga el catalogo mandado como parámetro
            caso contrario todos
        */
        if(catalogName){
            if(this.catalogoHabilitadoParaUsuarioLogeado(catalogName))
                catalogsToProcess.push(catalogName);
        }else{
            this.catalogs.forEach(c=>{
                if(!c.data){//solo se carga los catalogos que no tienen data y que están permitidos por el usuario
                    c.ver=this.catalogoHabilitadoParaUsuarioLogeado(c.catalogName);
                    //console.log(c.name);
                    //console.log(habilitado);
                    if(c.ver)
                        catalogsToProcess.push(c.catalogName);
                }
            });
        }
        /*
         solo se envia el primer catálogo ya que la función
         se llama a si misma para cargar el resto
         si no esta en cache lo trae del servidor
        */
        this.setCacheCatalog(catalogsToProcess[0], catalogsToProcess);
    }
    catalogoHabilitadoParaUsuarioLogeado(catalogName){
        if(this.userService.esUsuarioPublicoFarmacoVigilancia() && catalogName=='catalogReacciones')
             return true;
            
        if(this.userService.currentUser.ModulosAcceso.Ventas){
            if(catalogName=="cartera" || catalogName=="clients" || catalogName=="products" 
            || catalogName=="banks" || catalogName=="syncOrders")
                return true;
        }
        if(this.userService.currentUser.ModulosAcceso.FarmacoVigilancia){
            if(catalogName=="catalogReacciones")
                return true;
        }
        if(this.userService.currentUser.ModulosAcceso.ControlCalidad){
            if(catalogName=="bodegasConUbicaciones" 
                || catalogName=="bodegas"    
                || catalogName=="ubicaciones"
            )
            return true;
        }
        if(this.userService.currentUser.ModulosAcceso.Bodega){
            if(catalogName=="bodegasConUbicaciones" 
                || catalogName=="bodegas"    
                || catalogName=="catalogProveedoresEM"
                || catalogName=="ubicaciones"
                || catalogName=="catalogProveedoresConPedidos")
                return true;
        }
        return false;
    }
    /*
    Esta función se llama a si misma para hasta que se procesen todos los catálogos
    de catalogsToProcess
    */
    setCacheCatalog(catalogName, catalogsToProcess){
        let call=this.storageService.onValueArrived().subscribe(catalog=>{
            call.unsubscribe();
            if(catalog){
                //console.log(catalog);
                //se carga el catalogo
                this.catalogs.forEach(c=>{
                    if(c.catalogName==catalogName){
                        c.date=catalog.date;
                        c.data=catalog.data;
                    }
                });
                this.removeCatalogToProcess(catalogName, catalogsToProcess);
            }else{// trae desde el servidor
                this.syncCatalog(catalogName, catalogsToProcess);
            }
            /**
             * se espera 1 seg para volver a invocar a la función
             * para que no llame al servidor 2 veces en el proceso de sincronizacion
             */
            const sleepTime=50;
            timer(sleepTime).subscribe(x => { 
                if(catalogsToProcess.length>0){
                    this.setCacheCatalog(catalogsToProcess[0], catalogsToProcess);
                }else
                    this.catalogLoaded.next(); // se cargaron todos los catalogos y finaliza el procesamiento    
            });
        });
        this.storageService.prepareGetObject(catalogName);
    }
    
    syncCatalog(catalogName,catalogsToProcess){
        /**
         * Se incorpora esta bandera porque se intenta sincronizar
         * hasta que encuentre conexión a internet o mientras
         * existan catálogos pendientes de procesar en catalogsToProcess
         * invocado desde la función: setCacheCatalog
         */
        if(!catalogName)
            return;
        if(this.sincronizandoCatalogo)
            return;
        if(!this.online){
          const msg="Se intentará sincronizar el catálogo "+catalogName+" cuando tenga conexión a internet :)";
          console.log(msg);
          this.syncMessage.next(msg);
          return;
        }
        
        this.sincronizandoCatalogo=true;
        this.sincronizandoCatalogos.next();
        let cacheCatalog=this.catalogs.filter(c=>c.catalogName==catalogName).shift();
        this.syncMessage.next('Sincronizando '+cacheCatalog.name+'...');
        let url=ConfigUtils.getUrlFromEndPointName(catalogName);
        if(cacheCatalog.requireVendor)
            url+='/'+this.userService.currentUser.IdVendor;
        //console.log(url);
        let call=this.http.get<any>(url).subscribe(catalog=>{
          //console.log(catalogName);
          //console.log(catalog);
          call.unsubscribe();
          if(catalog){
            this.removeCatalogToProcess(catalogName, catalogsToProcess);
            if(catalog.length==1 && catalog[0].error){ //si hay un error interno
                this.sincronizandoCatalogo=false;
                this.errorToSyncCatalog.next(catalog[0].error);
            }else{
                let call2=this.storageService.onObjSetted().subscribe(()=>{
                    call2.unsubscribe();
                    this.syncMessage.next('');
                    this.catalogSyncronized.next();
                    this.sincronizandoCatalogo=false;
                });
                this.setLocalCatalog(catalogName, catalog);
            }
          }
        },error=>{
          this.sincronizandoCatalogo=false;
          console.log(error);
          if(error && error.message)
            this.errorToSyncCatalog.next(error.message);
          else
            this.errorToSyncCatalog.next('Error de comunicacion, intente mas tarde!!');
        });
    }
    
    /**
     * 
     * Esta función guarda en el caché local del computador
     */
    setLocalCatalog(catalogName, catalog){
        let cacheItem={
            data: catalog,
            date: StringUtils.getCurrentDate()
        };
        this.updateCacheCatalog(catalogName, cacheItem);
        this.storageService.setObject(catalogName, cacheItem);
    }
    removeCatalogToProcess(catalogName, catalogsToProcess){
        let index=catalogsToProcess.indexOf(catalogName,0);
        if(index>-1){
            catalogsToProcess.splice(index,1);
        }
    }
    updateCacheCatalog(catalogName, catalog){
        this.catalogs.forEach(c=>{
            if(c.catalogName==catalogName){
                c.data=catalog.data;
                c.date=catalog.date;
                this.cacheCatalogUpdated.next(catalogName);
            }
        });
    }
    getCatalogByName(catalogName){
        var catalog=this.catalogs.find(c=>c.catalogName==catalogName);
        if(catalog)
            return catalog.data;
        return null;
    }
    getCatalogObjByName(catalogName){
        for(let catalog of this.catalogs){
            if(catalog.catalogName==catalogName)
                return catalog;
        }
    }
    clearCacheCatalogs(){
        this.catalogs.forEach(c=>{
            this.clearCacheCatalog(c.catalogName);
            c.data=null;
            c.date=null;
        });
    }
    private clearCacheCatalog(catalogName){
        let call=this.storageService.onObjRemoved().subscribe(()=>{
            call.unsubscribe();
            this.catalogsRemoved.next(catalogName);
        });
        this.storageService.removeObject(catalogName);
    }
}