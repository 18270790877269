import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ClientServices } from './services/clientServices';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrderServices } from './services/orderServices';
import { NetworkServices } from './services/networkServices';
import { TabServices } from './services/tabServices';
import { filter } from 'rxjs/operators';
import { HeaderServices } from './services/headerServices';
import { ConfigPage } from './pages/config/config.page';
import { ConfigUtils } from './utils/configUtils';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appTitle: string;
  appSubTitle: string;
  viewBackButton:boolean;
  online:boolean=false;
  statusMessage:string=null;
  esBddPruebas=false;
  
  private _previousUrl: string;
  private _currentUrl: string;
  private _routeHistory: string[];
  private redirectedTologin: boolean;
  constructor(
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      public router: Router,
      public route: ActivatedRoute,
      public orderService: OrderServices,
      private headerServices: HeaderServices
  ) {
    this.initializeApp();
    this.initNavigation();
    this.appTitle='JB - Mobile';
    this.viewBackButton=false;
    this.orderService.onShowStatusMessage().subscribe(msg=>this.statusMessage=msg);
    this.checkSiEsBddPruebas();
  }
  checkSiEsBddPruebas(){
    var url=ConfigUtils.getUrlFromEndPointName('urlConsultaLoteArticulo');
    if(url)
      this.esBddPruebas=url.includes('apptest');
  }
  initNavigation(){
    this._routeHistory = [];
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._setURLs(event);
      });
  }
  //se dispara cada que se cambia de página
  // se oculta el botón de 'atras' cuando la página está en el arreglo pagesToHideBackButton
  private _setURLs(event: NavigationEnd): void {
    const pagesToHideBackButton=[
        'home',
        'login',
        'first-login',
        'reacciones'
      ];
    const tempUrl = this._currentUrl;
    if(tempUrl!='/')
      this._previousUrl = tempUrl;
    this._currentUrl = event.url;
    this._routeHistory.push(this._previousUrl);
    //console.log('current:',this._currentUrl);
    //console.log('prev:',this._previousUrl);
    this.changePageTitle(this._currentUrl);
    let pageName=this.getPageName(this._currentUrl);
    if(pageName!=''){
      let bloquedPage= (pagesToHideBackButton.find(u=>u==pageName));
      this.viewBackButton=(!bloquedPage);
    }else{ // porque siempre que se cambia de página primero pasa por la pagina vacía
      if(!this.redirectedTologin){
        this.redirectedTologin=true;
        this.router.navigateByUrl('login');
      }
    }
  }

  changePageTitle(pageUrl){
    const me=this.headerServices.getPageTitle(pageUrl);
    this.appTitle=me.titulo;
    this.appSubTitle=me.subtitulo;
  }
  getPageName(url){
    if(url){
      let matrix = url.split('/');
      if(matrix.length>0)
        return matrix[matrix.length-1];
    }
    return null;
  }
  initializeApp() {
    this.platform.ready().then(() => {
      //this.router.navigateByUrl('login');
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
     
      //Consulto la páguina de inicio, si no indico nada se va al login
      // this.route.queryParams.subscribe(params => {
      //   console.log(params); // ...?initPage=reacciones
      //   const initPage = params.initPage;
      //   if(initPage)
      //     this.router.navigateByUrl(initPage);
      //   else
      //     this.router.navigateByUrl('login');
      // });
      
    });
  }
  myGoBack(){
    this.router.navigateByUrl(this._previousUrl);
  }
}
