import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/stringUtils';
import { PagoMsg } from '../msg/PagoMsg';
import { HttpClient } from '@angular/common/http';
import { NetworkServices } from './networkServices';
import { NotificationServices } from './notificationServices';
import { StorageServices } from './storageService';
import { ConfigUtils } from '../utils/configUtils';

export class PagoServices{
  pago: PagoMsg;
  private _printRecibo:Subject<any>=new Subject<any>();
  constructor(){
    this.clearPago();
  }
  clearPago(){
    this.pago=new PagoMsg();
  }
  printRecibo(){
    this._printRecibo.next();
  }
  onPrintRecibo():Observable<any>{
    return this._printRecibo.asObservable();
  }
}


