import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfigUtils } from '../utils/configUtils';

@Injectable()
export class SolicitudTrasladoServices {
    public static currentST:any={};
    private subTransferirComponentesCompleated: Subject<any>=new Subject<any>();
    constructor(public http: HttpClient){}
    onTransferirComponentesCompleated():Observable<string>{
        return this.subTransferirComponentesCompleated.asObservable();
    }
    setTransferirComponentesCompleated(){
        this.subTransferirComponentesCompleated.next();
    }
    getSts_OfLiberadas(){
        const url = ConfigUtils.getUrlFromEndPointName('solicitudTransferencia')+'/getST_OF_Liberadas';
        return this.http.get<any>(url);
    }
    getComponetesConLotesById(id){
        const url = ConfigUtils.getUrlFromEndPointName('solicitudTransferencia')+'/GetComponetesConLotesById/'+id;
        return this.http.get<any>(url);
    }
}
