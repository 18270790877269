import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CatalogServices } from './catalogServices';

@Injectable()
export class ClientServices {
    selectedClient: any;
    static clients;
    private _selectClient: Subject<any>=new Subject<any>();
    private _selectMenuItem: Subject<string>=new Subject<string>();
    
    constructor(private catalogService: CatalogServices){
        
    }
    selectClient(client){
        this.selectedClient=client;
        this._selectClient.next(client);
    }
    selectClientById(id){
        if(!ClientServices.clients)
            this.setClientsCatalog();
        let client=ClientServices.clients.filter(c=>c.id==id).shift();
        this.selectClient(client);
    }
    setClientsCatalog() {
        ClientServices.clients=this.catalogService.getCatalogObjByName('clients').data;
    }
    selectMenuItem(menuItemTitle){
        this._selectMenuItem.next(menuItemTitle);
    }
    onSelectMenuItem():Observable<any>{
        return this._selectMenuItem.asObservable();
    }
    onSelectedClient():Observable<any>{
        return this._selectClient.asObservable();
    }
}
