import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ArrayUtils } from 'src/app/utils/arrayUtils';

@Component({
  selector: 'app-search-combo',
  templateUrl: './search-combo.component.html',
  styleUrls: ['./search-combo.component.scss'],
})
export class SearchComboComponent implements OnInit {
  @Input()
  data: any[]=[]; //el arreglo debe tener un miembro name sino da error
  
  @Input()
  showLabel:boolean=true;
  
  @Input()
  label: string;

  @Input()
  formControlName:string;
  
  _selectedItem:any={};
  @Input()
  
  get selectedItem():any{
    return this._selectedItem;
  }
  set selectedItem(value){
    if(!value)
      return;
    if(this._selectedItem && this._selectedItem.name==value.name)
      return;
    this.selectItem(value);
  }
  @Output() selectedItemChange = new EventEmitter<any>();

  dataResp: any[]=[];
  
  constructor() { }
  selectItem(item){
    this._selectedItem=item;
    this.dataResp=[];
    this.selectedItemChange.emit(item);
  }
  
  ngOnInit() {}
  
  search(e){
    if(!this.data)
      return;
    //si ya se selecciono el item no busa
    if(this._selectedItem.name)
      return;
    let token = e.detail.value;
    if(token && token!=''){
      this.dataResp=this.filter(token);
      this.dataResp= this.dataResp.sort((a,b)=>{ //sort by name
        if(a.name>b.name)
          return 1;
        return -1;
      });
    }
  }
  cancel(e){
    this._selectedItem={};
  }
  filter(token){
    let ms=[];
    token = token.toLocaleLowerCase();
    const matrixToken = token.split(' ');
    for (let item of this.data) {
      let strInToSearch=item.name;
      
      strInToSearch=strInToSearch.toLocaleLowerCase();
      if (ArrayUtils.contieneTokens(strInToSearch, matrixToken)) {
        ms.push(item);
      }
    }
    return  ms;
  }
  
  
}
