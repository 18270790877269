import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatalogServices } from 'src/app/services/catalogServices';
import { ConfigUtils } from 'src/app/utils/configUtils';
import { StringUtils } from 'src/app/utils/stringUtils';

@Component({
  selector: 'app-read-qr',
  templateUrl: './read-qr.component.html',
  styleUrls: ['./read-qr.component.scss'],
})
export class ReadQRComponent implements OnInit {
  @Input()
  valor:string;

  @Input()
  label:string;

  
  _bodegaSeleccionada:any;
  @Input()
  get bodegaSeleccionada():any{
    return this._bodegaSeleccionada;
  }
  set bodegaSeleccionada(value){
    /*if(!value)
      return;*/
    if(this._bodegaSeleccionada && value && this._bodegaSeleccionada.name==value.name)
      return;
    this._bodegaSeleccionada=value;
    this.bodegaSeleccionadaChange.emit(value);
    this.onBodegaChange(this._bodegaSeleccionada);
  }
  @Input()
  codBodegaSeleccionada:string;
  @Output() bodegaSeleccionadaChange = new EventEmitter<string>();
  @Output() codBodegaSeleccionadaChange = new EventEmitter<string>();


  @Input()
  tipo:string; //lote o ubicacion

  @Output() valorChange = new EventEmitter();
  @Output() valorClear = new EventEmitter();

  bodegas: any[]=[];
  catalogUbicaciones: any[]=[];
  errorUbicacion: string=null;
  bodegaSeleccionadaTieneUbicaciones: boolean;
  mostrarQr: boolean=true;

  constructor(private catalogService:CatalogServices) { 
    this.catalogService.onCacheCatalogUpdated().subscribe(catalogName=>{
      if(catalogName=='bodegasConUbicaciones'){
        this.setCatalogoBodega();
      }
      if(catalogName=='ubicaciones'){
        this.setCatalogoUbicaciones();
      }
    });
  }

  ngOnInit() {
    if(this.tipo=='ubicacion'){
      this.mostrarQr=false;
      this.setCatalogoBodega();
      this.setCatalogoUbicaciones();
    }
      
  }
  setCatalogoBodega(){
    let me=this.catalogService.getCatalogByName('bodegasConUbicaciones');
    if(me && me.Error){
      console.log('Error: catalogo bodegas '+me.Error);
    }else{
      me.Bodegas.forEach(bodega=>{
        this.bodegas.push({
          "name": bodega
        });
      });
    }
  }
  setCatalogoUbicaciones(){
    this.catalogUbicaciones=[];
    let me=this.catalogService.getCatalogByName('ubicaciones');
    if(me && me.Error){
      console.log('Error: catalogo bodegas '+me.Error);
    }else{
      this.catalogUbicaciones=me.Ubicaciones;
    }
  }
  validarUbicacion(){
    const ubicacionHasta=this.valor;
    this.errorUbicacion=null;
    if(!this.existeUbicacion(ubicacionHasta)){
      this.errorUbicacion="La ubicación selecccionada no esta registrada en SAP";
      return false;
    }
    return true;
  }
  bodegaTieneUbicaciones(codBodega){
    if(this.catalogUbicaciones){
      let ubicaciones=this.catalogUbicaciones.find(u=>u.includes(codBodega));
      if(ubicaciones)
        return true; 
    }
    return false;
  }
  existeUbicacion(ubicacion){
    for(let item of this.catalogUbicaciones){
      if(item==ubicacion)
        return true;
    }
    return false;
  }
  
  qrValChange(e){
    if(e && e.detail){
      const me=e.detail.value;
       //si tiene la url se hace la consulta del servicio web para traer el detalle del QR
      let tokenBusqueda=null;
      switch(this.tipo){
        case 'lote':
          tokenBusqueda=ConfigUtils.getUrlFromEndPointName('urlConsultaLoteArticulo');
          break;
        case 'ubicacion':
          tokenBusqueda=ConfigUtils.getUrlFromEndPointName('urlConsultaUbicacion');
          break;
      }
      if(tokenBusqueda && StringUtils.contains(me, tokenBusqueda)){
        this.valor = me.replace(tokenBusqueda,'');
        if(this.tipo=='ubicacion' && this.codBodegaSeleccionada){
          this.valor=this.codBodegaSeleccionada+'-'+this.valor;
          this.validarUbicacion();
        }
        this.valorChange.emit(this.valor);
      }
    }
  }
  clearQr(){
    this.bodegaSeleccionada={}; //para que se encere el searchBar de bodega
    this.valor=null;
    this.valorClear.emit();
  }
  onBodegaChange(bodega){
    if(bodega){
      this.bodegaSeleccionadaTieneUbicaciones=this.bodegaTieneUbicaciones(bodega.name);
      if(this.tipo=='ubicacion')
        this.mostrarQr=this.bodegaSeleccionadaTieneUbicaciones;
      this.codBodegaSeleccionadaChange.emit(bodega.name);
    }
  }
}
