import { Component, Input, OnInit } from '@angular/core';
import { DocCarteraMsg } from 'src/app/msg/carteraMsg';
import { NumberUtils } from 'src/app/utils/numberUtils';

@Component({
  selector: 'app-pago-item',
  templateUrl: './pago-item.component.html',
  styleUrls: ['./pago-item.component.scss'],
})
export class PagoItemComponent implements OnInit {

  @Input()
  doc: DocCarteraMsg;

  constructor() { }

  ngOnInit() {}
  transform(value){
    return NumberUtils.transform(value);
  }

}
