import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/stringUtils';
import { PagoMsg } from '../msg/PagoMsg';
import { HttpClient } from '@angular/common/http';
import { NetworkServices } from './networkServices';
import { NotificationServices } from './notificationServices';
import { StorageServices } from './storageService';
import { ConfigUtils } from '../utils/configUtils';
import { ReaccionesMsg } from '../msg/regReaccionesMsg';
import { UserServices } from './userServices';
import { Router } from '@angular/router';

@Injectable()
export class PrintServices{
  private doPrint: Subject<string>=new Subject<string>();

  onPrint():Observable<string>{
    return this.doPrint.asObservable();
  }
  constructor(public route: Router){}

  print(html){
    this.route.navigateByUrl('printer'); //nombre de la pagina en donde se imprime
    this.doPrint.next(html);
  }
}


