import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StringUtils } from './stringUtils';


export class NumberUtils {
    static transform(value){
        //fx(1094345) -> 10.943.45
        let me= StringUtils.setThousandSeparator(value);
        //fx(10.943.45) -> 10.943,45
        let matrix=me.split('.');
        let ms='';
        let i=0;
        matrix.forEach(item=>{
          if(i>0)
            ms+=(item.length==2) ? ',' : '.';
          ms+=item;
          i++;
        });
        
        return ms;
      }
    static round(me, numDecimales){
      const ms=Number(me).toFixed(numDecimales);
      return Number(ms);
    }
}
