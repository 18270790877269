import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/stringUtils';
import { PagoMsg } from '../msg/PagoMsg';
import { HttpClient } from '@angular/common/http';
import { NetworkServices } from './networkServices';
import { NotificationServices } from './notificationServices';
import { StorageServices } from './storageService';
import { ConfigUtils } from '../utils/configUtils';
import { ReaccionesMsg } from '../msg/regReaccionesMsg';
import { UserServices } from './userServices';

@Injectable()
export class BodegaServices{
  static currentSolicitudEM: any;
  public entradaMercanciaCompraSave: Subject<boolean>=new Subject<boolean>();
  
  constructor(public http: HttpClient, private userService: UserServices){}
  onEntradaMercanciaCompraSaved():Observable<boolean>{
    return this.entradaMercanciaCompraSave.asObservable();
  }
  getPedidosByProveedor(codProveedor){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getPedidosPorProveedor/'+codProveedor;
    return this.http.get<any>(url);
  }
  getFacturasReservaPorProveedor(codProveedor){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getFacturasReservaPorProveedor/'+codProveedor;
    return this.http.get<any>(url);
  }
  getPedidosByFacturaReserva(docNum){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getPedidosPorFacturaReserva/'+docNum;
    return this.http.get<any>(url);
  }
  getEMByProveedor(codProveedor){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getEMPorProveedor/'+codProveedor;
    return this.http.get<any>(url);
  }
  getArticulosConStock(){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getArticulosConStock';
    return this.http.get<any>(url);
  }
  getLotesConStockByCodArticulo(codArticulo){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getLotesConStockByCodArticulo/'+codArticulo;
    return this.http.get<any>(url);
  }
  guardarEntradaMercanciaPorCompra(documento){
    if(this.userService.currentUser && this.userService.currentUser.UserName)
      documento.responsable=this.userService.currentUser.UserName;
    let url = ConfigUtils.getUrlFromEndPointName('entradaMercanciaCompra');
    return this.http.post<any>(url,documento)
  }
  getUbicacionesYDetArticuloPorLote(lote){
    let url = ConfigUtils.getUrlFromEndPointName('bodega');
    url+='/getUbicacionesYDetArticuloPorLote/'+lote;
    return this.http.get<any>(url);
  }
  transferirEntreUbicaciones(me){
    let url = ConfigUtils.getUrlFromEndPointName('transferenciaStock');
    console.log(url);
    console.log(me);
    return this.http.post<any>(url,me)
  }
  transferirFromST(me){
    let url = ConfigUtils.getUrlFromEndPointName('tsFromST');
    return this.http.post<any>(url,me)
  }
}


