import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BodegaServices } from './bodegaServices';

@Injectable()
export class HeaderServices{
    private pageChange: Subject<string>=new Subject<string>();

    constructor(){
    }
    getPageTitle(pageUrl){
      let title="JB - Mobile";
      let subTitle='';
      const tituloFV='Farmacovigilancia';
      const subtituloFV='FOR-ARH-007 Recepción de eventos adversos y reacciones adversas de medicamentos'
      //console.log(pageUrl);
      switch(pageUrl){
          case '/tabs/cartera':
            title="Cartera";
            break;
          case '/tabs/pago':
            title="Registrar Pago";
            break;
          case '/tabs/order':
            title="Pedido";
            break;
          case '/tabs/order_report':
            title="Pedidos Sincronizados";
            break;
          case '/tabs/notification':
            title="Notificaciones";
            break;
          case '/tabs/client':
            title="Menú Cliente";
            break;
          case '/tabs/config':
            title="Configuraciones";
            break;
          case '/tabs/reacciones':
            title=tituloFV;
            subTitle=subtituloFV;
            break;
          case '/reacciones':
            title=tituloFV;
            subTitle=subtituloFV;
            break;
          case '/tabs/emCompra':
            title="Entrada de Mercancía";
            subTitle="Por Compra";
            break;
          case '/tabs/registroEm':
            title="Registrar Mercancía";
            if(BodegaServices.currentSolicitudEM && BodegaServices.currentSolicitudEM.tipo)
              subTitle="Por "+BodegaServices.currentSolicitudEM.tipo;
            break;
          case '/tabs/emFRCompra':
            title="Registrar Mercancía";
            subTitle="Por Factura de reserva de Compra";
            break;
          case '/tabs/tipoEmImp':
            title="Imprimir QR Entrada de Mercancia";
            break;
          case '/tabs/listadoEmPorProveedor':
            title="Imprimir Etiqueta";
            subTitle="Entradas de Mercancía con stock por Proveedor";
            break;
          case '/tabs/listadoEmPorArticulo':
            title="Imprimir Etiqueta";
            subTitle="Entradas de Mercancía con stock por Artículo";
            break;
          case '/tabs/moverArticulos':
            title="Mover Articulos";
            subTitle="Dentro de la bodega";
            break;
          case '/tabs/pickingProduccion':
            title="Picking Producción";
            subTitle="Materia Prima - Material de Empaque";
            break;
          case '/tabs/procesarPickingProduccion':
            title="Procesar Picking";
            subTitle="Producción";
            break;
        }
        this.pageChange.next(pageUrl);
        let ms= {titulo: title, subtitulo: subTitle};
        //console.log(ms);
        return ms;
    }
    onPageChanged():Observable<any>{
      return this.pageChange.asObservable();
  }
}
