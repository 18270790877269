import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/stringUtils';
import { PagoMsg } from '../msg/PagoMsg';
import { HttpClient } from '@angular/common/http';
import { NetworkServices } from './networkServices';
import { NotificationServices } from './notificationServices';
import { StorageServices } from './storageService';
import { ConfigUtils } from '../utils/configUtils';
import { ReaccionesMsg } from '../msg/regReaccionesMsg';
import { UserServices } from './userServices';

@Injectable()
export class ReaccionServices{
  reaccion: any;
  private _clearReaccion: Subject<any>=new Subject<any>();
  private _editReaccion: Subject<any>=new Subject<any>();
  constructor(public http: HttpClient, public userService: UserServices){}
  clearReaccion(){
    this.reaccion=null;
    this._clearReaccion.next();
  }
  onClearReaccion(){
    return this._clearReaccion.asObservable();
  }
  editReaccion(){
    this._editReaccion.next();
  }
  onEditReaccion(){
    return this._editReaccion.asObservable();
  }
  saveReaccion(){
    UserServices.logMe.Obs='Guardando Notificación de Fármaco vigilancia';
    this.userService.log();
    const url = ConfigUtils.getUrlFromEndPointName('reacciones');
    return this.http.post<any>(url,[this.reaccion]);
  }
  
}


