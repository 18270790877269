import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class StorageServices{
    error: string;
    valueArrive: Subject<any>=new Subject<any>();
    objRemoved: Subject<any>=new Subject<any>();
    objSetted: Subject<any>=new Subject<any>();
    constructor(private storage: Storage){}
    onObjSetted():Observable<any>{
        return this.objSetted.asObservable();
    }
    onValueArrived():Observable<any>{
        return this.valueArrive.asObservable();
    }
    onObjRemoved():Observable<any>{
        return this.objRemoved.asObservable();
    }

    setObject(key: string, value: any){
        try {
            this.error=null;
            let obj=JSON.stringify(value);
            let resp=this.storage.set(key, obj);
            this.objSetted.next();
            return true;
        } catch (error) {
            this.error=error;
            return false;
        }
    }
    async removeObject(key: string){
        var resp = await this.storage.remove(key);
        this.objRemoved.next();
    }
    async prepareGetObject(key: string) {
        let ms=null;
        //try {
            await this.storage.create();
            let result = await this.storage.get(key);
            if (result != null) {
                ms=JSON.parse(result);
            }
            this.valueArrive.next(ms);
        /*}catch (reason) {
            this.error=reason;
            console.log('error from storage services');
            console.log(this.error);
        }*/
    }
    async prepareGetObject2(key: string) {
        let ms=null;
        try {
            let result = await this.storage.get(key);
            if (result != null) {
                ms=JSON.parse(result);
            }
            return ms;
        }catch (reason) {
            this.error=reason;
            console.log('error from storage services');
            console.log(this.error);
        }
    }
 }