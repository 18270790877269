import { Component, Input, OnInit } from '@angular/core';
import { BasePage } from '../../basePage';
import { AlertController } from '@ionic/angular';
import { CatalogServices } from 'src/app/services/catalogServices';
import { callInstance } from '@ionic-native/core/decorators/common';

@Component({
  selector: 'app-sync-catalogs',
  templateUrl: './sync-catalogs.page.html',
  styleUrls: ['./sync-catalogs.page.scss'],
})
export class SyncCatalogsPage extends BasePage implements OnInit {
  @Input()
  catalog: any;
  public sincronizando:boolean;
  public syncMsg:string='Sincronizar';
  constructor(ac: AlertController, public catalogService: CatalogServices) { 
    super(ac);
  }
  ngOnInit() {
    
  }
  syncCatalog(catalogName){
    /**
     * Se requiere desuscribir cada observable porque este control es
     * instanceado varias veces en la pagina conf.
     */
    let call1=this.catalogService.onErrorToSyncCatalog().subscribe(msg=>{
      call1.unsubscribe();
      this.sincronizando=false;
      this.syncMsg='Sincronizar';
      console.log(msg);
      this.show(msg);
    });
    let call2=this.catalogService.onSincronizandoCatalogos().subscribe(()=>{
      call2.unsubscribe();
      this.sincronizando=true;
    });
    let call3=this.catalogService.onSyncMessage().subscribe(msg=>{
      call3.unsubscribe();
      this.syncMsg=msg;
    });
    let call4=this.catalogService.onCatalogSyncronized().subscribe(()=>{
      call4.unsubscribe();
      this.sincronizando=false;
      this.syncMsg='Sincronizar'; 
    });
    this.catalogService.syncCatalog(catalogName, [catalogName]);
  }
}
