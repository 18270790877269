import { StringUtils } from "../utils/stringUtils";

export class BaseTxObj{
    key: string;
    setUniqueKey () {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        this.key= StringUtils.getUnicKey();
    };
}