import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BasePage } from 'src/app/pages/basePage';
import { BodegaServices } from 'src/app/services/bodegaServices';
import { SocioNegocioServices } from 'src/app/services/socioNegocioServices';

@Component({
  selector: 'app-escoger-doc-origen',
  templateUrl: './escoger-doc-origen.component.html',
  styleUrls: ['./escoger-doc-origen.component.scss'],
})
export class EscogerDocOrigenComponent extends BasePage implements OnInit {
  @Input()
  tipo: string;

  proveedores: any[]=[];
  cargandoProveedores: boolean=false;
  cargandoDocumentosOrigen: boolean=false;
  currentProveedor:any;
  documentosOrigen: any[]=[];

  constructor(ac:AlertController,  
    private bodegaService: BodegaServices, 
    public snService: SocioNegocioServices, 
    private router: Router) {
      super(ac);
    }

  ngOnInit() {
    this.setCatalogos();
    this.bodegaService.onEntradaMercanciaCompraSaved().subscribe(tieneCantidadesPendientes=>{
      if(tieneCantidadesPendientes){
        this.onProveedorSelected(this.currentProveedor);//para que se actualicen las cantidades pendientes
      }
    });
  }
  setCatalogos(){
    this.cargandoProveedores=true;
    this.snService.getProveedoresPorTipo(this.tipo).subscribe(me=>{
      console.log(me);
      this.cargandoProveedores=false;
      if(me && me.error)
        this.show(me.Error);
      else{
        if(me && me.data && me.data.length==0)
          this.show("No existen proveedores con "+this.tipo+" pendientes de ingreso de mercancía!")
        this.proveedores=me.data;
      }
    },error=>this.showComunicationError(error))

  }
  onProveedorSelected(proveedor){
    console.log(proveedor);
    if(proveedor && proveedor.codProveedor){
      this.currentProveedor=proveedor;
      console.log(this.tipo);
      switch(this.tipo){
        case "Factura de Reserva":
          this.cargarFacturasPorProveedor(proveedor);
          break;
        case "Pedido de Compra":
            this.setPedidos(proveedor);
            break;
      }
    }
      this.cargarFacturasPorProveedor(proveedor);
  }
  
  
  cargarFacturasPorProveedor(proveedor){
    this.cargandoDocumentosOrigen=true;
    this.bodegaService.getFacturasReservaPorProveedor(proveedor.codProveedor).subscribe(me=>{
      this.cargandoDocumentosOrigen=false;
      this.documentosOrigen=me;
      this.documentosOrigen.forEach(f=>f.proveedor=proveedor.name);
    }, error=>this.showComunicationError(error));
  }
  setPedidos(proveedor){
    this.cargandoDocumentosOrigen=true;
    this.bodegaService.getPedidosByProveedor(proveedor.codProveedor).subscribe(me=>{
      console.log(me);
      this.cargandoDocumentosOrigen=false;
      if(me.error){
        this.show(me.error);
        return;
      }
      this.documentosOrigen=me.Pedidos;
      this.documentosOrigen.forEach(f=>f.proveedor=proveedor.name);
    },
      error=>this.showComunicationError(error)
    );
  }
  showComunicationError(error){
    this.cargandoProveedores=false;
    this.cargandoDocumentosOrigen=false;
    if(error && error.message)
        this.show(error.message); 
      else
        this.show("Error de comunicación!!");
  }
  ingresarMercancia(documento){
    BodegaServices.currentSolicitudEM = documento;
    BodegaServices.currentSolicitudEM.tipo = this.tipo;
    this.router.navigateByUrl('tabs/registroEm');
  }

}
