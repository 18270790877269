const conf=[
    {
        "name": "urlWebServices",
        "value":[
            {
                "endPointName": "cartera",
                "url": "http://services.jbp.com.ec/api/vendor/getCarteraByCodVendedor"
            },
            {
                "endPointName": "clients",
                "url": "http://services.jbp.com.ec/api/socioNegocio/getBycodVendedor"
            },
            {
                "endPointName": "socioNegocio",
                "url": "http://services.jbp.com.ec/api/socioNegocio"
            },
            {
                "endPointName": "products",
                "url": "http://services.jbp.com.ec/api/product/getSellProducts"
            },
            {
                "endPointName": "order",
                "url": "http://services.jbp.com.ec/api/orden"
            },
            {
                "endPointName": "pago",
                "url": "http://services.jbp.com.ec/api/pago"
            },
            {
                "endPointName": "syncOrders",
                "url": "http://services.jbp.com.ec/api/orden/getOrdersByVendor"
            },
            {
                "endPointName": "banks",
                "url": "http://services.jbp.com.ec/api/catalogos/getbancosecuador"
            },
            {
                "endPointName": "reacciones",
                "url": "http://services.jbp.com.ec/api/reacciones/"
            },
            {
                "endPointName": "catalogReacciones",
                "url": "http://services.jbp.com.ec/api/reacciones/getCatalogos"
            },
            {
                "endPointName": "catalogProveedoresEM",
                "url": "http://services.jbp.com.ec/api/socioNegocio/getProveedoresEM"
            },
            {
                "endPointName": "bodega",
                "url": "http://services.jbp.com.ec/api/bodega"
            },
            {
                "endPointName": "entradaMercanciaCompra",
                "url": "http://services.jbp.com.ec/api/EntradaMercanciaCompra"
            },
            {
                "endPointName": "user",
                "url": "http://services.jbp.com.ec/api/user"
            },
            {
                "endPointName": "bodegasConUbicaciones",
                "url": "http://services.jbp.com.ec/api/bodega/getBodegasConUbicaciones"
            },
            {
                "endPointName": "bodegas",
                "url": "http://services.jbp.com.ec/api/bodega/getBodegas"
            },
            {
                "endPointName": "ubicaciones",
                "url": "http://services.jbp.com.ec/api/bodega/getUbicaciones"
            },
            {
                "endPointName": "transferenciaStock",
                "url": "http://services.jbp.com.ec/api/tsUbicaciones"
            },
            {
                "endPointName": "tsFromST",
                "url": "http://services.jbp.com.ec/api/tsFromST"
            },

            {
                "endPointName": "solicitudTransferencia",
                "url": "http://services.jbp.com.ec/api/st"
            },
            {
                "endPointName": "urlConsultaLoteArticulo",
                "url": "http://app.jbp.com.ec/consultaLote?lote="
            },
            {
                "endPointName": "urlConsultaUbicacion",
                "url": "http://app.jbp.com.ec/consultaUbicacion?ubicacion="
            },
        ]
    },
    {
        "name": "masterPwd",
        "value": "AtLsV971*"
    }
]
export default conf

