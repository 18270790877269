import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})

export class ClientInfoComponent implements OnInit {
  @Input()
  client: any;

  @Input()
  showDetailInfo: boolean;
  constructor() { }

  ngOnInit() {
    this.showDetailInfo=false;
  }
  showDetail(){
    this.showDetailInfo= !this.showDetailInfo;
  }
  

}
