import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SearchClientPage } from './search-client.page';
import { ClientInfoModule } from '../client-info/client-info.module';

@NgModule({
  declarations: [SearchClientPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(), 
    ClientInfoModule
  ],
  exports: [
    SearchClientPage
  ]
  
})
export class SearchClientPageModule {}
