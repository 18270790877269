import { Component, OnInit, Input } from '@angular/core';
import { NetworkServices } from 'src/app/services/networkServices';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  online:boolean;
  constructor(ns: NetworkServices) {
    ns.onNetworkChange().subscribe(online=>this.online=online);
  }

  ngOnInit() {}

}
