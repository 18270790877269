import { Injectable } from '@angular/core';
import { StorageServices } from './storageService';
import { Subject, Observable} from 'rxjs';
import { PagoServices } from './pagoServices';
import { Router } from '@angular/router';
import { PagoMsg } from '../msg/PagoMsg';
import { ClientServices } from './clientServices';
import { NetworkServices } from './networkServices';
import { HttpClient } from '@angular/common/http';
import { ConfigUtils } from '../utils/configUtils';
import { CatalogServices } from './catalogServices';
import { ReaccionServices } from './reaccionServices';
import { UserServices } from './userServices';


@Injectable()
export class NotificationServices{
    //list of items
    orders: any[]=[];
    pagos:  PagoMsg[]=[];
    reacciones: any[]=[]; // reacciones adversas a medicamentos notificados por visitadores medicos
    changeNumNotification: Subject<number>=new Subject<number>();
    numNotifactions: number=0;
    online: boolean;
    private itemsSetted: Subject<any>=new Subject<any>();
    private showMessage: Subject<any>=new Subject<string>();
    private showStatusMessage: Subject<string>=new Subject<string>();
    private busy: Subject<boolean>=new Subject<boolean>();
    private itemCacheSaved:Subject<any>=new Subject<any>();
    
    onItemCacheSaved():Observable<any>{
        return this.itemCacheSaved.asObservable();
    }
    onItemssSetted():Observable<any>{
        return this.itemsSetted.asObservable();
    }
    onBusy():Observable<boolean>{
        return this.busy.asObservable();
    }
    onShowMessage():Observable<any>{
        return this.showMessage.asObservable();
    }
    onShowStatusMessage():Observable<string>{
        return this.showStatusMessage.asObservable();
    }
    
    constructor(
        private storageService: StorageServices,
        public pagoService: PagoServices,
        public userService: UserServices,
        public reaccionServices: ReaccionServices,
        private catalogService: CatalogServices,
        public route: Router,
        public http: HttpClient,
        public networkService: NetworkServices,
        private clientService: ClientServices){
            this.networkService.onNetworkChange().subscribe(online=>{
                this.online=online;
            });
    }
    editCacheItem(cacheItemToEdit){
        switch(cacheItemToEdit.cacheName){
            case 'pagos':
                this.pagoService.pago=cacheItemToEdit.cacheItem;
                this.clientService.selectClientById(this.pagoService.pago.CodCliente);
                this.route.navigateByUrl('tabs/pago');
                break;
            case 'reacciones':
                this.reaccionServices.reaccion=cacheItemToEdit.cacheItem;
                this.route.navigateByUrl('tabs/reacciones');
                this.reaccionServices.editReaccion();
                break;
        }
    }
   
    prepareNotifications(){
        this.prepareNotification('orders');
        this.prepareNotification('pagos');
        this.prepareNotification('reacciones');
    }
    async prepareNotification(cacheName){
        let items= await this.storageService.prepareGetObject2(cacheName);
        this.showItems(items, cacheName);
    }
    pushNotification(numNotification){
        this.numNotifactions=numNotification;
        this.changeNumNotification.next(this.numNotifactions);
    }
    onNumNofificationChange():Observable<number>{
        return this.changeNumNotification.asObservable();
    }
    // puede ser pagos, pedidos o cualquier objeto suceptible de estar
    // en el area de notificaciones
    async saveItemOnCache(item, cacheName, deleteRequest=false){//update or insert order
        if(!item)
            return;
        let items= await this.storageService.prepareGetObject2(cacheName);
        if(!items)
            items=[];
        let idItem=items.findIndex(p=> 
            p.key==item.key);
        if(idItem>=0){
            if(deleteRequest){ //delete
                console.log('after delete');
                console.log(items);
                items.splice(idItem,1);
                
            }else //edit
                items[idItem]=item;
        } 
        else //insert
            items.push(item);
        this.setColsInfo(cacheName,items);
        this.saveItemsOnCache(items,cacheName);
    }
    
    setColsInfo(cacheName, items){
        switch(cacheName){
          case 'pagos':
            items.forEach(item=>{
              item.col1= 'USD '+ item.totalPagado.toFixed(2);
              item.col2= item.tipoPago;
            });
            break;
          case 'reacciones':
            items.forEach(item=>{
              item.col1= item.nombres +' '+item.apellidos;
              item.col2= item.fechaRegistro;
            });
            break;
        }
    }
    saveItemsOnCache(items, cacheName){
        let call=this.storageService.onObjSetted().subscribe(()=>{
            call.unsubscribe();
            this.showItems(items,cacheName);
            this.itemCacheSaved.next();
        });
        this.storageService.setObject(cacheName,items);
    }
    private showItems(items, cacheName){
        switch(cacheName){
            case 'orders':
                this.orders=items;
                break;
            case 'pagos':
                this.pagos=items;
                break;
            case 'reacciones':
                this.reacciones=items;
                break;
        }
        let numOrders = 0;
        let numPagos = 0;
        let numReacciones = 0;
        if(this.orders)
            numOrders=this.orders.length;
        if(this.pagos)
            numPagos=this.pagos.length;
        if(this.reacciones)
            numReacciones=this.reacciones.length;    
        let numNotifactions=numOrders + numPagos + numReacciones;
        
        this.pushNotification(numNotifactions);
    }
    getUrlWs(cacheName){
        let endPointName;
        switch(cacheName){
            case 'orders':
                endPointName='order';
                break;
            case 'pagos':
                endPointName='pago';
                break;
            case 'reacciones':
                endPointName='reacciones';
                break;
        }
        if(!endPointName)
            return null;
        return ConfigUtils.getUrlFromEndPointName(endPointName);
    }
    getTitulo(cacheName){
        switch(cacheName){
            case 'orders':
                return 'pedidos';
            case 'pagos':
                return 'pagos';
            case 'reacciones':
                return 'reacciones';
        }
        return 'items';
    }
    showMessageFromControl(msg, idControl){
        let ms={
            "msg": msg,
            "idControl": idControl
        }
        this.showMessage.next(ms);
    }
    syncItems(items, cacheName, idControl){
        let titulo=this.getTitulo(cacheName);
        if(!this.online){
            this.showMessageFromControl(
                'Necesita estar conectado a internet para enviar los '+titulo+'!!',
                idControl
            );
            return;
        }
        let urlWs=this.getUrlWs(cacheName);
        if(!urlWs){
            this.showMessageFromControl(
                'No se ha configurado la url del servicio web para: '+cacheName,
                idControl
            );
            return;
        }
        this.busy.next(true);
        this.showStatusMessage.next('Sincronizando '+titulo+'...');      
        console.log(urlWs);
      
        let call=this.http.post<any>(urlWs,items).subscribe(resps=>{
            call.unsubscribe();
            this.busy.next(false);
            this.showStatusMessage.next('');
            let i=0;
            let msgNotSync='';

            resps.forEach(resp=>{//always num resps are equal to items to Sync
                console.log('resp SAP:')
                console.log(resp);
                if(resp=='ok' || resp=='Anteriormente ya se procesó este item!'){
                    items[i].synchronized=true;
                    
                }else{
                    items[i].synchronized=false;
                    msgNotSync+=items[i].CodCliente+items[i].col1+ ': '+resp;
                }
                i++;
            });
            let sycronizedItems=items.filter(o=>o.synchronized);
            console.log('Items sincronizados:');
            console.log(sycronizedItems);
            if(cacheName=='reacciones'){
                UserServices.logMe.Obs='Sincronizada Notificación de Fármaco vigilancia';
                this.userService.log();
            }
            if(cacheName=='pagos'){
                this.updateCartera(sycronizedItems);
            }
            //TO DO: guardar pagos sincronizados en la app
            //this.saveSyncronizedOrdersOnCache(sycronizedOrders)
            this.removeSyncronizedItemFromCache(sycronizedItems, cacheName);
            
            if(sycronizedItems.length==items.length)
            this.showMessageFromControl(
                'Se han sincronizado los items seleccionados',
                idControl
            );
            else{
                this.showMessageFromControl(
                    'Al menos un item no se sincronizó reporte lo siguiente al administrador del sistema: '+msgNotSync,
                    idControl
                );
            }
            
        },
            error=>{
                console.log(error);
                this.busy.next(false);
                this.showMessageFromControl(
                    'Error de comunicación, intente mas tarde!',
                    idControl
                );
            }
        );
        
    }
    updateCartera(pagos: PagoMsg[]){
        let carteraObj=this.catalogService.getCatalogObjByName('cartera');
        if(carteraObj && carteraObj.data){
            let cartera=carteraObj.data;
            pagos.forEach(pago=>{
                //para hacer el calculo del abono a la ultima factura
                let saldo = pago.totalPagado;
                pago.facturasAPagar.forEach(factura=>{
                    //se busca el registro correspondiente de cartera
                    let idCartera=cartera.findIndex(c=> c.NumFolio==factura.numDoc);
                    if(idCartera>0){
                        let saldoCartera=cartera[idCartera].SaldoVencido;
                        if(saldo>=factura.toPay){
                            factura.pagado=factura.toPay;
                            saldo-=factura.toPay
                        }else{//abono a la ultima factura
                            factura.pagado=saldo;
                        }
                        let newSaldoCartera=saldoCartera-factura.pagado;
                        if(newSaldoCartera>0.5) //todavia queda saldo 
                            cartera[idCartera].SaldoVencido=newSaldoCartera; //se actualiza el nuevo saldo
                        else{ // se ha pagado el saldo en su totalidad (un saldo < 0.5 se considera pagado)
                            // elimino de cartera
                            cartera.splice(idCartera,1);
                        }
                    }
                });
                
            });
            //actualizo el catalog de cartera
            this.catalogService.setLocalCatalog('cartera', cartera);
            this.pagoService.clearPago();
        }
    }
    removeSyncronizedItemFromCache(sycronizedItems, cacheName){
        switch(cacheName){
            case 'pagos':
                this.removeSyncronizedItemFromCacheItemsGiven(this.pagos, sycronizedItems, cacheName)
                break;
            case 'orders':
                this.removeSyncronizedItemFromCacheItemsGiven(this.orders, sycronizedItems, cacheName)
                break;
            case 'reacciones':
                this.removeSyncronizedItemFromCacheItemsGiven(this.reacciones, sycronizedItems, cacheName)
                break;
        }
    }
    removeSyncronizedItemFromCacheItemsGiven(items, sycronizedItems, cacheName){
        sycronizedItems.forEach(syncItem => {
            let idItem=items.findIndex(p=> p.key==syncItem.key);
            items.splice(idItem,1);
        });
        //safe on cache
        this.saveItemsOnCache(items,cacheName);
    }
    
}