import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigUtils } from '../utils/configUtils';

enum eDocType{
    factura="Factura",
    notaCredito="Nota Credito",
}
@Injectable()
export class SocioNegocioServices {
  constructor(
    public http: HttpClient
  ){}
  getProveedoresPorTipo(tipo):Observable<any>{
    var endPoint='';
    switch(tipo){
      case "Factura de Reserva":
        endPoint='getProveedoresConFacturasReserva';
        break;
      case "Pedido de Compra":
        endPoint='getProveedoresConPedidos';
        break;
    }
    const url=ConfigUtils.getUrlFromEndPointName('socioNegocio')+'/'+endPoint;
    return this.http.get<any>(url);
  }
}
