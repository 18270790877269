import { NumberUtils } from "../utils/numberUtils";
import { BaseTxObj } from "./baseTxObj";
import { CacheItemMsg } from "./CacheItemMsg";
import { DocCarteraMsg } from "./carteraMsg";

export class PagoMsg extends CacheItemMsg{
    totalPagado:number=0; 
    facturasAPagar: DocCarteraMsg[]; 
    tiposPago: TipoPagoMsg[]; 
    tiposPagoToSave:any[];
    nextDocNum: string; //para poder habilitar el siguiente documento en caso de una edicion
    fotosComprobantes: string[];
    comment: string;
    constructor(){
        super();
        this.facturasAPagar=[]; 
        this.tiposPago=[];
        this.fotosComprobantes=[];
    }
    totalAPagar: number=0;
    static setTotalAPagar(me: PagoMsg){
        me.totalAPagar=0;
        me.facturasAPagar.forEach(fact=>{
            me.totalAPagar+= DocCarteraMsg.getToPayMasProntoPago(fact);
            me.totalAPagar=NumberUtils.round(me.totalAPagar,2);
        });
        if(me.totalAPagar<0)
            me.totalAPagar=0;
    }
    static setTotalPagado(me: PagoMsg){
        me.totalPagado=0;
        if(me.tiposPago && me.tiposPago.length>0){
            me.tiposPago.forEach(tp=>me.totalPagado+=tp.monto);
            me.totalPagado=NumberUtils.round(me.totalPagado,2);
        }
    }
}

export class TipoPagoMsg extends BaseTxObj{
    tipoPago: string;
    monto: number;
    bancoTxt: string;
    //cheque
    CodigoBanco: string;
    FechaVencimientoChequeStr: string;
    NumCheque: number;
    Posfechado: string;

    //transferencia
    CodigoCuentaJB: string;
    NumTransferencia: number;

    constructor(){
        super();
    }
    
}

