import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ClientInfoComponent } from './client-info.component';

@NgModule({
  declarations: [
    ClientInfoComponent,
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(), 
  ],
  exports: [
    ClientInfoComponent,
  ]
})
export class ClientInfoModule { }