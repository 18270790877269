import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {eActionPages} from '../msg/pageMsg';

@Injectable()
export class TabServices{
    _marcAsTouched: Subject<string>=new Subject<string>();
    
    constructor(){}

    marcAsTouched(tabTitle){
        this._marcAsTouched.next(tabTitle);
    }
    onMarcAsTouched():Observable<string>{
        return this._marcAsTouched.asObservable();
    }
    

}
