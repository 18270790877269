import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: 'first-login',
    loadChildren: () => import('./pages/first-login/first-login.module').then( m => m.FirstLoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./components/header/header.component').then( m => m.HeaderComponent)
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/ventas/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'cartera',
    loadChildren: () => import('./pages/ventas/cartera/cartera.module').then( m => m.CarteraPageModule)
  },
  {
    path: 'sync-catalogs',
    loadChildren: () => import('./pages/configPages/sync-catalogs/sync-catalogs.module').then( m => m.SyncCatalogsPageModule)
  },
  {
    path: 'order-report',
    loadChildren: () => import('./pages/reports/order-report/order-report.module').then( m => m.OrderReportPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
   {
    path: 'product',
    loadChildren: () => import('./pages/product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'cartera-total',
    loadChildren: () => import('./pages/reports/cartera-total/cartera-total.module').then( m => m.CarteraTotalPageModule)
  },
  {
    path: 'search-client',
    loadChildren: () => import('./components/search-client/search-client.module').then( m => m.SearchClientPageModule)
  },
  {
    path: 'pago',
    loadChildren: () => import('./pages/ventas/pago/pago.module').then( m => m.PagoPageModule)
  },
  {
    path: 'reacciones',
    loadChildren: () => import('./pages/reacciones/reacciones.module').then( m => m.ReaccionesPageModule)
  },
  {
    path: 'entrada-mercancia-compra',
    loadChildren: () => import('./pages/bodega/EntradaMercancia/entrada-mercancia-compra/entrada-mercancia-compra.module').then( m => m.EntradaMercanciaCompraPageModule)
  },
  {
    path: 'registro-em',
    loadChildren: () => import('./pages/bodega/EntradaMercancia/registro-em/registro-em.module').then( m => m.RegistroEMPageModule)
  },
  {
    path: 'print-qr-em',
    loadChildren: () => import('./pages/bodega/print-qr-em/print-qr-em.module').then( m => m.PrintQrEMPageModule)
  },
  {
    path: 'listado-em',
    loadChildren: () => import('./pages/bodega/ImprimirQR_EM/listado-em/listado-em.module').then( m => m.ListadoEMPageModule)
  },
  {
    path: 'printer',
    loadChildren: () => import('./pages/printer/printer.module').then( m => m.PrinterPageModule)
  },
  {
    path: 'mover-articulo',
    loadChildren: () => import('./pages/bodega/mover-articulo/mover-articulo.module').then( m => m.MoverArticuloPageModule)
  },
  {
    path: 'picking-produccion',
    loadChildren: () => import('./pages/bodega/picking-produccion/picking-produccion.module').then( m => m.PickingProduccionPageModule)
  },
  {
    path: 'tipo-em',
    loadChildren: () => import('./pages/bodega/ImprimirQR_EM/tipo-em/tipo-em.module').then( m => m.TipoEMPageModule)
  },
  {
    path: 'listado-em-por-articulo',
    loadChildren: () => import('./pages/bodega/ImprimirQR_EM/listado-em-por-articulo/listado-em-por-articulo.module').then( m => m.ListadoEmPorArticuloPageModule)
  },
  {
    path: 'escoger-tipo-em',
    loadChildren: () => import('./pages/bodega/EntradaMercancia/escoger-tipo-em/escoger-tipo-em.module').then( m => m.EscogerTipoEMPageModule)
  },
  {
    path: 'em-factura-reserva-compra',
    loadChildren: () => import('./pages/bodega/EntradaMercancia/em-factura-reserva-compra/em-factura-reserva-compra.module').then( m => m.EMFacturaReservaCompraPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      preloadingStrategy: PreloadAllModules,
      useHash: true, 
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
