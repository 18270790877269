import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigUtils } from '../utils/configUtils';

enum eDocType{
    factura="Factura",
    notaCredito="Nota Credito",
}
@Injectable()
export class LoginServices {
  constructor(
    public http: HttpClient
  ){

  }
  login(me):Observable<any>{
    if(me && !me.AppName)
      me.AppName=ConfigUtils.appName;
    const url=ConfigUtils.getUrlFromEndPointName('user')+'/login';
    console.log(url);
    return this.http.post<any>(url,me);
  }
}
