import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogServices } from 'src/app/services/catalogServices';
import { StorageServices } from 'src/app/services/storageService';
import { UserServices } from 'src/app/services/userServices';
import { ClientServices } from 'src/app/services/clientServices';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-config-vendor',
  templateUrl: './config-vendor.page.html',
  styleUrls: ['./config-vendor.page.scss'],
})
export class ConfigVendorPage implements OnInit {

  catalogsDeleting: boolean=false;
  
  //@Input()
  //mostrar: boolean=false; //por alguna extraña razón en el menu del cliente siempre se muestra este componente al dar click en cualquier opocion

  constructor(
    private route: Router, 
    private catalogService: CatalogServices, 
    private storageService: StorageServices,
    private userServices: UserServices,
    private alertController: AlertController,
    private clientService: ClientServices) {

  }

  ngOnInit() {
  }
  
  async confirmarCambioUsuario() {
    const alert = await this.alertController.create({
      header: 'Está seguro que desea cambiar de usuario? Si hacer esto se borrará toda la información sin sincronizar',
      buttons: [
        {
          text: 'No',
          handler: () => {},
        },
        {
          text: 'Si',
          handler: () => {
            this.changeUser();
          },
        },
      ],
    });
    await alert.present();
  }

  changeUser(){
    this.userServices.pin=null;
    this.userServices.currentUser=null;
    this.clientService.selectedClient=null;
    this.catalogsDeleting=true;
    let call=this.storageService.onObjRemoved().subscribe(()=>{
      call.unsubscribe();
      console.log('pin removed');
      let call2=this.storageService.onObjRemoved().subscribe(()=>{
        call2.unsubscribe();
        console.log('user removed');
        let call3=this.catalogService.onCatalogsRemoved().subscribe(()=>{
          call3.unsubscribe();
          this.catalogsDeleting=false;
          this.route.navigateByUrl('first-login');
        });
        this.catalogService.clearCacheCatalogs();
      });
      this.storageService.removeObject('user');
    });
    this.storageService.removeObject('pin');
  }

}
