import { Injectable } from '@angular/core';

@Injectable()
export class StringUtils {
    static contains(texto: string, textoBusqueda: string){
        if(texto && texto.indexOf(textoBusqueda)==0)
            return true;
        return false;
    }
    static quitarTildes(me: string): string {
        let ms = me.replace('á', 'a');
        ms = me.replace('é', 'e');
        ms = me.replace('í', 'i');
        ms = me.replace('ó', 'o');
        ms = me.replace('ú', 'u');
        ms = me.replace('ñ', 'n');
        ms = me.replace('Á', 'A');
        ms = me.replace('É', 'E');
        ms = me.replace('Í', 'I');
        ms = me.replace('Ó', 'O');
        ms = me.replace('Ú', 'U');
        ms = me.replace('Ñ', 'N');
        return ms;
    }
    static getTwoDigitNumber(me: number) {
        if ( me < 10) {
            return '0' + me;
        }
        return me;
    }
    static getCurrentDate(onlyDate=false){
        const year=new Date().getFullYear();
        const month= StringUtils.getTwoDigitNumber(new Date().getMonth()+1);
        const day=StringUtils.getTwoDigitNumber(new Date().getDate());
        const time=new Date().toLocaleTimeString();
        let ms= year+'-'+month+'-'+day;
        if(!onlyDate)
            ms+=' '+time;
        return ms;
    }
    static setThousandSeparator(value: any) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    static getUnicKey(){
        return '_' + Math.random().toString(36).substr(2, 9);
    }
}
