import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/stringUtils';
import { DocCarteraMsg } from '../msg/carteraMsg';
import { NotificationServices } from './notificationServices';
import { PagoMsg } from '../msg/PagoMsg';
import { CatalogServices } from './catalogServices';
import { NumberUtils } from '../utils/numberUtils';

enum eDocType{
    factura="Factura",
    notaCredito="Nota Credito",
}
@Injectable()
export class CarteraServices {
  constructor(
    private notificationService:NotificationServices,
    private catalogService: CatalogServices
  ){

  }
  private carteraClicked: Subject<any>=new Subject<any>();
  onCarteraClicked():Observable<any>{
    return this.carteraClicked.asObservable();
  }
  clickCartera(){
    this.carteraClicked.next();
  }
  getTotalSaldoVencido(cartera){
    let totalSaldoVencido=0;
    if(cartera){
      //las notas de credito llegan con signo negativo
      cartera.forEach(doc=>{
        //La cartera solo aplica a factura
        if(doc.TipoDocumento=='Factura' || doc.TipoDocumento=='Cheque Protestado')
          totalSaldoVencido+=doc.SaldoVencido;
      });
    }
    return totalSaldoVencido;
  }
  mapCartera(cartera){
    let ms=[];
    cartera.forEach(c=>{
      // se carga el tipo documento por cada item de cartera
      // si no ha sido cargado previamente
      if(!this.existDocType(c.TipoDocumento,ms)){
        let item:any={};
        item.docType=c.TipoDocumento;
        item.ranges=[];
        ms.push(item);
      }
      //documentos por tipo documento
      ms.forEach(item=>{
        if(item.docType==c.TipoDocumento){
          if(!this.existRange(c.RangoDiasVencido, item.ranges)){
            let r:any={};
            r.range=c.RangoDiasVencido;
            r.totalRange=0;
            r.docs=[];
            // se requiere visualizar los cheques protestados 
            // para que los vendedores muestren esta información al cliente.
            r.showDocs=(c.TipoDocumento=='Factura' || c.TipoDocumento=='Cheque Protestado');
            item.ranges.push(r);
          }
          item.ranges.forEach(obj=>{
            if(obj.range==c.RangoDiasVencido){
              let doc:DocCarteraMsg= new DocCarteraMsg();
              obj.totalRange+=c.SaldoVencido;
              /*if(c.TipoDocumento==eDocType.factura){
                obj.totalRange+=c.SaldoVencido
              }
              if(c.TipoDocumento==eDocType.notaCredito){
                obj.totalRange+=c.TotalDocumento
              }*/
              doc.total=c.TotalDocumento;
              doc.toPay=c.SaldoVencido;
              doc.numDoc=c.NumFolio;
              doc.date=c.FechaDocumento;
              doc.dueDate=c.FechaVencimiento;
              doc.tipoDocumento=c.TipoDocumento;
              doc = this.getCacheDocState(doc); //para habilitar el siguiente documento para edición
              doc.retenciones=c.Retenciones;
              doc.pagos=c.Pagos;
              doc.toPayMasProntoPago=DocCarteraMsg.getToPayMasProntoPago(doc);
              const sumRetenciones=DocCarteraMsg.getMontoRetenciones(doc);
              if(NumberUtils.round(doc.toPayMasProntoPago,2) > 
                NumberUtils.round(sumRetenciones,2))//si el saldo no es la retención
                obj.docs.push(doc);
              obj.docs= obj.docs.sort((a,b)=>{ //sort docs by numDoc
                if(a.numDoc>b.numDoc)
                  return 1;
                return -1;
              });
            }
          })
        }
      });
    });
    //se habilita el primer registro de cada tipo documento
    ms.forEach(tipoDocumento=>{
      if( (tipoDocumento.docType=='Factura' || tipoDocumento.docType=='Cheque Protestado') && tipoDocumento.ranges){
        let firstDoc=tipoDocumento.ranges[0].docs[0];
        if(firstDoc && !firstDoc.isOnCache)
          firstDoc.habilitado=true;
      }
    });
    return ms;
  }
  getCacheDocState(doc: DocCarteraMsg){
    if(this.notificationService.pagos){
      for(let pago of this.notificationService.pagos){
        //enableNexDocument
        if(pago.nextDocNum==doc.numDoc){
          doc.habilitado=true;
        }
        for(let cacheDoc of pago.facturasAPagar){
          if(doc.numDoc==cacheDoc.numDoc){
            cacheDoc.isOnCache=true;
            return cacheDoc;
          }
        }
      } 
    }
    return doc;
  }
  
  private existDocType(docType, matrix: any[]) {
    if(matrix.length==0)
        return false;
    for(let item of matrix){
        if(item.docType==docType)
        return true;
    }
    return false;
  }
  private existRange(range, matrix: any[]) {
      if(matrix.length==0)
          return false;
      for(let obj of matrix){
          if(obj.range==range)
          return true;
      }
      return false;
  }
  getCarteraByClient(cartera, idClient){
      let ms= cartera.filter(c=>c.CodSocioNegocio==idClient);
      //se ordena para que las facturas aparezcan primero como tipo de documento
      /*
        Ej. Que en la cartera aparezca primero las facturas,
         luego los cheques protestados
      */
      //OrdenTipoDocumento se setea en la vista de cartera del lado del servidor
      
      
      //se ordena por rango
      ms= ms.sort((a,b)=>{
        if(a.OrdenRango>b.OrdenRango)
          return -1;
        return 1;
      });
      //se ordena por tipo documento
      ms= ms.sort((a,b)=>{
        if(a.OrdenTipoDocumento>b.OrdenTipoDocumento)
          return 1;
        return -1;
      });
      //console.log(ms);
      return ms;
  }
  getCarteraCatalog(){
    let carteraObj= this.catalogService.getCatalogObjByName('cartera');
    return carteraObj;
  }
}
