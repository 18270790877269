import conf from '../../assets/configProduccion';
//import conf from '../../assets/configTest';
//import conf from '../../assets/configLocal';

export class ConfigUtils {
    static altoEtiqueta:number=95;
    static appName:string='JB - Mobile';
    static getUrlFromEndPointName(endPointName){
        let endPoints= conf.filter(c=>c.name=='urlWebServices').shift();
        if(endPoints && Array.isArray(endPoints.value) ) {
            let endPoint=endPoints.value.filter(ep=>ep.endPointName==endPointName).shift();
            if(endPoint)
                return endPoint.url;
        } 
        return null;
    }
    static getStrConfValue(confName):string{
        let confItem = conf.filter(c=>c.name==confName).shift();
        if(confItem && (typeof confItem.value === 'string')){
            return confItem.value;
        }
        return null;
    }
    
}
