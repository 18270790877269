import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BasePage } from 'src/app/pages/basePage';
import { CatalogServices } from 'src/app/services/catalogServices';
import { ClientServices } from 'src/app/services/clientServices';
import { HeaderServices } from 'src/app/services/headerServices';
import { OrderServices } from 'src/app/services/orderServices';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent extends BasePage implements OnInit  {
  @Output()
  onProductSelected = new EventEmitter<any>();

  @Input()
  product: any;
  
  @Input()
  clearSelectedProduct:boolean=false;
  
  porcentajeDescFin: number;
  products: any[]=[];
  productsResp: any[]=[];
  showList:boolean=false;

  constructor(
      a: AlertController, 
      public cs: ClientServices, 
      catalogService: CatalogServices,
      orderService: OrderServices,
      headerService: HeaderServices) {
    super(a); 
    this.products=catalogService.getCatalogByName('products');
    catalogService.onCacheCatalogUpdated().subscribe(catalogName=>{
      if(catalogName=='products'){
        this.products=catalogService.getCatalogByName('products');
      }
    });
    this.showList=true;
    orderService.onSendOrderToEdit().subscribe(me=>{
      this.showList=false;
      console.log("editando orden")
    });
    headerService.onPageChanged().subscribe(page=>{
      if(page=='/tabs/product' && this.product){
        this.setPrice();
      }
    });
  }
  ngOnInit() {}
  
  selectProduct(product){
    this.setPrice();
    this.onProductSelected.emit(product);
  }
  
  setPrice(){
    if(this.product && this.product.prices && this.cs.selectedClient){
      const priceObj=this.product.prices.filter(p=>p.id==this.cs.selectedClient.priceListId).shift();
      if(!priceObj){
        this.porcentajeDescFin=0;
        this.show("El cliente no tiene asignado lista de precio para este producto");
        return;
      }else{
        this.product.priceListName=priceObj.priceList;
        this.product.priceList=priceObj.price;
        this.porcentajeDescFin=this.cs.selectedClient.porcentajeDescuentoFinanciero;
        if(this.porcentajeDescFin){
          const descuentoFinanciero=priceObj.price*(this.porcentajeDescFin/100);
          this.product.price=priceObj.price-descuentoFinanciero;
        }
          
      }
    }
  }
}


