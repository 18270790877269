import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfigUtils } from '../utils/configUtils';

@Injectable()
export class UserServices {
    private changeUser: Subject<any>=new Subject<any>();
    pin: number;
    currentUser: any;
    public static logMe:any={};
    public static moduloAutorizado:string;
    private moduloAutorizadoSubject: Subject<string>=new Subject<string>();
    constructor(public http: HttpClient){}
    onChangeUser():Observable<any>{
        return this.changeUser.asObservable();
    }
    setNewUser(user){
        this.changeUser.next(user);
    }
    onModuloAutorizado():Observable<string>{
        return this.moduloAutorizadoSubject.asObservable();
    }
    setModuloAutorizado(modulo){
        UserServices.moduloAutorizado = modulo;
        this.moduloAutorizadoSubject.next(modulo);
    }
    
    esUsuarioPublicoFarmacoVigilancia(){
        if(!this.currentUser)
            return true;
        return false;
    }
    //se registra en el log el usuario logeado con la observacion puesta en UserServices.logMe.Obs
    log(msg:string=null){
        if(UserServices.moduloAutorizado)
            msg='Modulo '+UserServices.moduloAutorizado+': '+msg;
        const url = ConfigUtils.getUrlFromEndPointName('user')+'/log';
        if(this.currentUser){
            UserServices.logMe.userName=this.currentUser.Nombre;
        }
        UserServices.logMe.AppName=ConfigUtils.appName;
        if(msg)
            UserServices.logMe.Obs=msg;
        this.http.post<any>(url,UserServices.logMe).subscribe(resp=>{
               // console.log("Respuesta Log:",resp);
            }, error=>{
                if(error)
                   console.log(error);
            }
        )
    }
}
