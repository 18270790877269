import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NetworkServices } from './networkServices';
import { StorageServices } from './storageService';
import { Subject, Observable, Subscribable, Subscription, throwError } from 'rxjs';
import { ConfigUtils } from '../utils/configUtils';

import { NotificationServices } from './notificationServices';
import { JsonPipe } from '@angular/common';
import { eCacheOrderType, eOrderItemAction, Order } from '../msg/orderMsg';
import { CatalogServices } from './catalogServices';
import { Router } from '@angular/router';
import { ClientServices } from './clientServices';


@Injectable()
export class OrderServices{
    
    private urlWs: string;
    private showMessage: Subject<string>=new Subject<string>();
    private showStatusMessage: Subject<string>=new Subject<string>();
    private busy: Subject<boolean>=new Subject<boolean>();
    private ordersSetted: Subject<any[]>=new Subject<any[]>();
    private _clearCurrentOrder: Subject<any[]>=new Subject<any[]>();

    private online:boolean=false;
    private _removeOrderToUpdateFromCache:Subject<any>=new Subject<any>();
    private cacheOrderEdited:Subject<any>=new Subject<any>();
    private _sendOrderToEdit:Subject<any>=new Subject<any>();
    isEditing:boolean=false; //when user press other buton like back or client tab. Used to prevent lost data of current order
    
    constructor(public http: HttpClient, 
                public networkService: NetworkServices,
                private notificationService: NotificationServices,
                public storageService: StorageServices,
                private catalogService: CatalogServices,
                private route: Router,
                private clientService: ClientServices){
        this.networkService.onNetworkChange().subscribe(online=>{
            this.online=online;
        });
        this.urlWs= ConfigUtils.getUrlFromEndPointName('order');
    }
    sendOrderToView(me){
        this._sendOrderToEdit.next(me);
    }
    onSendOrderToEdit():Observable<any>{
        return this._sendOrderToEdit.asObservable();
    }
    onClearCurrentOrder():Observable<any>{
        return this._clearCurrentOrder.asObservable();
    }
    clearCurrentOrder(){
        this._clearCurrentOrder.next();
    }
    onCacheOrderEdited():Observable<any>{
        return this.cacheOrderEdited.asObservable();
    }
    onRemoveOrderToUpdateFromCache():Observable<any>{
        return this._removeOrderToUpdateFromCache.asObservable();
    }
    onOrdersSetted():Observable<any[]>{
        return this.ordersSetted.asObservable();
    }
    onBusy():Observable<boolean>{
        return this.busy.asObservable();
    }
    onShowMessage():Observable<string>{
        return this.showMessage.asObservable();
    }
    onShowStatusMessage():Observable<string>{
        return this.showStatusMessage.asObservable();
    }
    removeOrderFromCache(order){
        this.notificationService.saveItemOnCache(order,'orders',true);
    }
    
    removeOrderToUpdateFromCache(){
        let call=this.storageService.onObjRemoved().subscribe(()=>{//to prevent to load a updated order after do save action
          call.unsubscribe();
          this._removeOrderToUpdateFromCache.next();
        });
        this.storageService.removeObject('orderToEdit');
    }
    syncOrders(orders){
        if(!this.online){
            this.showMessage.next('Necesita estar conectado a internet para enviar los pedidos!!');
            return;
        }
        const ordersToSync=orders.filter(o=>o.toSync);
        if(!ordersToSync || ordersToSync.length===0){
            this.showMessage.next('Debe seleccionar al menos un pedido para sincronizar!!');
            return;
        }
        this.busy.next(true);
        this.showStatusMessage.next('Sincronizando pedidos...');      
        console.log(this.urlWs);
        let call=this.http.post<any>(this.urlWs,ordersToSync).subscribe(resps=>{
            call.unsubscribe();
            this.busy.next(false);
            this.showStatusMessage.next('');
            let i=0;
            let msgNotSync='';
            resps.forEach(resp=>{//always num resps are equal to orders to Sync
                console.log('resp SAP:')
                console.log(resp);
                if(resp=='ok' || resp=='Anteriormente ya se procesó esta orden!')
                    ordersToSync[i].synchronized=true;
                else{
                    ordersToSync[i].synchronized=false;
                    msgNotSync+=ordersToSync[i].CodCliente+' USD '+orders[i].total+ ': '+resp;
                }
                i++;
            });
            let sycronizedOrders=ordersToSync.filter(o=>o.synchronized);
            console.log('Ordenes sincronizadas:');
            console.log(sycronizedOrders);
            this.saveSyncronizedOrdersOnCache(sycronizedOrders)
            //store orders pending to sync
            orders=orders.filter(o=> !o.toSync);
            let ordersNotSync=ordersToSync.filter(o=> !o.synchronized);
            if(!ordersNotSync || ordersNotSync.length==0)
                this.showMessage.next('Se han sincronizado los pedidos seleccionados');
            else{
                this.showMessage.next('Al menos una orden no se sincronizó reporte lo siguiente al administrador del sistema: '+msgNotSync);
                //add orders not syncroniced
                ordersNotSync.forEach(o => orders.push(o));
            }
            //safe on cache
            this.notificationService.saveItemsOnCache(orders,'orders');
        },
            error=>{
                console.log(error);
                this.busy.next(false);
                this.showMessage.next('Error de comunicación, intente mas tarde! ' + error);
            }
        );
        
    }
    saveSyncronizedOrdersOnCache(syncronizedOrders: any) {
        const catalogName='syncOrders';
        let call=this.storageService.onValueArrived().subscribe(orders=>{
            call.unsubscribe();
            if(!orders){
                orders={};
                if(!orders.data)
                    orders.data=[];
            }
            syncronizedOrders.forEach(order => {
                //si no existe
                let duplicateOrder=orders.data.find(o=>o.key==order.key);
                if(!duplicateOrder)
                    orders.data.unshift(order);
            });
            let call2=this.storageService.onObjSetted().subscribe(()=>{
                call2.unsubscribe();
                let obj:any={};
                obj.data=orders;
                this.catalogService.updateCacheCatalog(catalogName,orders);
            });
            this.storageService.setObject(catalogName,orders);
        });
        this.storageService.prepareGetObject(catalogName);
    }
    showSelectedOrderOnOrderPage(slidingItem, order, cacheName, cacheType: eCacheOrderType){
        slidingItem.close();
        let call=this.storageService.onObjRemoved().subscribe(()=>{
        call.unsubscribe();
        this.clientService.selectedClient=this.getClientFromOrder(order);
        let call2=this.storageService.onObjSetted().subscribe(()=>{
            call2.unsubscribe();
            var me: any={};
            me.order=order;
            me.cacheType=cacheType;
            this.sendOrderToView(me);
            this.route.navigateByUrl('tabs/order');
        });
        this.storageService.setObject(cacheName,order);
        });
        this.storageService.removeObject(cacheName);
    }
    getClientFromOrder(order: any): any {
        let clients=this.catalogService.getCatalogByName('clients');
        let ms=clients.filter(c=>c.id==order.CodCliente).shift();
        return ms;
    }
 }