import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PagoItemComponent } from './pago-item.component';


@NgModule({
  declarations: [
    PagoItemComponent,
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(), 
  ],
  exports: [
    PagoItemComponent,
  ]
})
export class PagoItemModule { }